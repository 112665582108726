import React from 'react'
import SEO from "../common/SEO";
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';

const ChiSiamo = () => {
    return (
        <>
            <SEO title="Chi siamo" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-5.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">                                    
                                    <h1 className="title display-one">Chi Siamo</h1>
                                    <h4 className="subtitle">Competenti, innovativi, affidabili</h4> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

{/* Start Service Area  */}
<div className="service-area rn-section-gapTop rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Dal 1978 con passione, competenza ed innovazione.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">La nostra azienda è stata fondata nel 1978 da <b>Narciso Donegà</b> che ha 
messo a frutto la sua <b>lunga esperienza sartoriale</b> investendo in una nuova impresa.<br/>
Narciso ha trasmesso la sua <b>passione e le sue competenze al figlio Gino</b> che attualmente
si occupa della gestione operativa.
Gino ha saputo abbinare alle competenze specifiche <b>l’innovazione tecnologica</b> ed ha
effettuato importanti <b>investimenti in tecnologia, macchinari e formazione</b>.
</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <FooterOne />
                <Copyright />

            </main>
        </>
    )
}

export default ChiSiamo;
