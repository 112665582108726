import React from 'react'
import SEO from "../common/SEO";
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import BandoBox from '../elements/about/BandoPage';

const Servizi = () => {
    return (
        <>
            <SEO title="Servizi" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-11.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">                                    
                                    <h1 className="title display-one">I nostri servizi</h1>     
                                    <h4 className="subtitle">Modellatura, taglio, confezione</h4>                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

{/* Start Service Area  */}
<div className="service-area rn-section-gapTop ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="content">
                                    <h3 className="title">Modellatura</h3>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <p className="mb--10">Usiamo i più moderni sistemi computerizzati per la modellatura
</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-area rn-section-gapTop ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="content">
                                    <h3 className="title">Taglio</h3>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <p className="mb--10">Usiamo macchinari di ultima generazione con sistemi di taglio con macchina a controllo numerico e sistemi di taglio laser.
</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="content">
                                    <h3 className="title">Confezione</h3>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <p className="mb--10">Laboratori specializzati, competenti, precisi ed affidabili.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <BandoBox image="./images/about/virga.jpg"  image2="./images/about/bando-image.jpg" />

                <FooterOne />
                <Copyright />

            </main>
        </>
    )
}

export default Servizi;
