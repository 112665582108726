import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";

const PopupData = [
    {
        id: "01",
        image: './images/about/contact-image.jpg',
        popupLink: [
            '/videos/video.mp4',
        ],
    }
]

const BandoBox = ({image, image2}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                    {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">La nostra nuovissima <strong>Virga&reg;</strong> in azione.</h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Incrementare e rendere efficiente la produzione</h4>
                                            <p className="text">La produzione on-demand giornaliera è composta da una <b>molteplicità di ordini, modelli, varianti e opzioni, ognuno con materiali diversi</b>. La complessità intrinseca di questi ordini e la quantità dei dati associati rende molto difficile pianificare in modo efficiente la produzione, per via dell'aumento dei costi e dei tempi di lavorazione. Per questo motivo abbiamo scelto <b>Virga&reg;</b>, al fine di poter soddisfare ogni tipo di richiesta e con la prospettiva di un considerevole aumento della produzione. </p>
                                        </div>
                                    </li>                                    
                                </ul>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 align-items-center" >
                    <div className="col-lg-12 text-center">
                        <h4>REALIZZATO CON IL SOSTEGNO DI</h4>
                    <p>Asse Prioritario III – PROMUOVERE LA COMPETITIVITÀ DELLE PICCOLE E MEDIE IMPRESE<br />
Obiettivo “INVESTIMENTI IN FAVORE DELLA CRESCITA E DELL’OCCUPAZIONE” - POR FESR 2014 -2020<br />
Azione  III. 3.c.1.1 
</p>
                    </div>
                </div>
                <div className="row align-items-center" >
                    <div className="col-lg-12 text-center">
                        <img src={image2} className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BandoBox
