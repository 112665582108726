import React from 'react'
import SEO from "../common/SEO";
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const Contatti = () => {
    return (
        <>
            <SEO title="Contatti" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-10.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">                                    
                                    <h1 className="title display-one">Contatti</h1>
                                    <h4 className="subtitle">Tutti i nostri recapiti per essere sempre a tua disposizione</h4>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon" >
                                        <FiHeadphones color='#e95318' />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telefono</h4>
                                        <p><a href="tel:+390362992913">+39 0362.992913</a></p>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail color='#e95318' />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">e-Mail</h4>
                                        <p><a href="mailto:donega@donega.com">donega@donega.com</a></p>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin color='#e95318' />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Indirizzo</h4>
                                        <p>Via della Valle, 39<br/>20841 - Carate Brianza (MB)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div></div></div>

                <FooterOne />
                <Copyright />

            </main>
        </>
    )
}

export default Contatti;
