import React from 'react'
import SEO from "../common/SEO";
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import BandoBox from '../elements/about/BandoPage';

const Home = () => {
    return (
        <>
            <SEO title="Modellatura, taglio, confezione" />
            <main className="page-wrapper">
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">                                    
                                    <h1 className="title display-one">Donegà Narciso</h1>
                                    <h3 style={{color:'#fff'}} >di Donegà Gino</h3>
                                    <h4 className="subtitle"><span className="theme-gradient" style={{textTransform: 'none'}} >Operiamo sia nel settore uomo che nel settore donna<br />offrendo un servizio completo ai nostri clienti: modellatura, taglio e confezione.</span></h4>
                                    <div className="button-group mt--40 mt_sm--20" >
                                        <a className="btn-default btn-icon" target="_parent" href="/contatti">Contattaci</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Cosa possiamo fare per te"
                                    title = "I nostri servizi"
                                    description = "Grazie alla nostra lunga esperienza sartoriale, all'innovazione tecnologica e<br />agli investimenti in tecnologia, macchinari e formazione, possiamo offrire i seguenti servizi"
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                

                <FooterOne />
                <Copyright />

            </main>
        </>
    )
}

export default Home;
